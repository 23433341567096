<template>
    <div>
        <AForm
            ref="formRef"
            class="myform"
            :model="state.form"
            :rules="state.rules"
            @submit.prevent="btnUploadFile()"
            :wrapper-col="{ span: 14 }"
            :scroll-to-first-error="true">

            <a-row class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        label="Unggah Dokumen"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        has-feedback>
                        <a-input
                            v-if="state.isForm"
                            type="file"
                            required
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            @change="onFileChange"
                        ></a-input>
                    </a-form-item>
                </a-col>
            </a-row>

            <a-row class="form-row mb-4">
                <ACol :sm="4"/>
                <a-col :sm="20">
                    <ASpace>
                        <a-button
                            type="primary"
                            html-type="submit"
                            :loading="state.form.loading">Upload</a-button>
                        <AButton
                            @click="btnDownloadTemplate()"
                            :loading="state.loadingTemplate">Unduh Template</AButton>
                    </ASpace>
                </a-col>
            </a-row>
        </AForm>

        <AAlert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <div class="row">
            <div class="col-lg-12">
                <div class="card-placeholder">
                    <div class="card-body">
                        <div class="table-responsive">
                            <MdTable
                                :columns="state.columns"
                                :data-source="state.data"
                                row-key="code"
                                :row-selection="rowSelection"
                                size="small"
                                :loading="state.loading">
                                <template #tags="{ record }">
                                    <a-tag :color="record.errors.length == 0 ? 'green' : 'volcano'">
                                    <template #icon>
                                        <template v-if="record.errors.length == 0"> <CheckCircleOutlined /> Ya </template>
                                        <template v-else>
                                            <APopover trigger="click">
                                                <template #content>
                                                    <AList size="small" :data-source="record.errors">
                                                        <template #renderItem="{ item }">
                                                            <AListItem>{{ item }}</AListItem>
                                                        </template>
                                                    </AList>
                                                </template>
                                                <CloseCircleOutlined /> Tidak
                                            </APopover>
                                        </template>
                                    </template>
                                    </a-tag>
                                </template>
                            </MdTable>
                        </div>
                    </div>
                </div>
                <div class="card-placeholder">
                    <div class="card-body ml-3">
                        <a-button
                            :disabled="state.selectedRows.length === 0"
                            type="primary"
                            @click="handleSubmitOk"
                            :loading="state.loading">Simpan</a-button>
                    </div>
                </div>
            </div>
        </div>

         <AModal
            v-model:visible="state.result.isShow"
            title="Result"
            @cancel="handleModalCancel"
            @ok="handleModalCancel">
            <template #footer>
                <AButton key="back" type="primary" @click="handleShowCancel">OK</AButton>
            </template>
            <AAlert type="success" :message="`Sukses: ${state.result.valid}`"></AAlert>
            <AAlert type="error">
                <template #message>
                {{ `Gagal: ${state.result.failed}` }}<a-tree :tree-data="state.result.data" default-expand-all></a-tree> </template
            ></AAlert>
        </AModal>
    </div>
</template>
<script>
import {
    defineComponent,
    nextTick,
    reactive,
    ref,
    computed,
} from 'vue'
import apiClient from '@/services/axios'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons-vue'
import { Modal, message } from 'ant-design-vue'
import moment from 'moment'
import { itemProps } from 'ant-design-vue/lib/vc-menu'

export default defineComponent({
    components: {
        CheckCircleOutlined,
        CloseCircleOutlined,
    },
    setup(props, { emit }) {
        const errorMessage = ref()

        const formRef = ref();

        const state = reactive({
            columns: [
                {
                    title: 'Valid?',
                    dataIndex: 'valid',
                    slots: {
                        customRender: 'tags',
                    },
                },
                {
                    title: 'Kode Customer Curah',
                    dataIndex: 'customer',
                },
                {
                    title: 'Nama Customer Curah',
                    dataIndex: 'customer_name',
                },
                {
                    title: 'Nomor Pegawai KAM',
                    dataIndex: 'user_kam',
                },
                {
                    title: 'User KAM',
                    dataIndex: 'kam_name',
                },
            ],
            rules: {},
            endpoint: '/api/user-kam/upload',
            data: [],
            loading: false,
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            selectedRowKeys: [],
            selectedRows: [],
            result: {
                isShow: false,
                data: [],
                valid: 0,
                failed: 0,
            },
            isForm: true,
            form: {
                loading: false,
                files: null,
                file: null,
                month: null,
            },
        })

        const handleModalCancel = () => {
            emit('update:visible', false)
        }

        const disabledDate = current => {
            return current && current < moment().startOf('month')
        }

        const onFileChange = (e) => {
            var files = e.target.files || e.dataTransfer.files
            if (!files.length) return
            state.form.file = files[0]
        }

        const rowSelection = computed(() => {
            return {
                selectedRowKeys: state.selectedRowKeys,
                onChange: (selectedRowKeys, selectedRows) => {
                    state.selectedRowKeys = selectedRowKeys
                    state.selectedRows = selectedRows
                },
                getCheckboxProps: record => ({
                    disabled: record.errors.length > 0,
                }),
            }
        })

        const btnUploadFile = async () => {
            // validation form
            await formRef.value.validate()
                .catch(() => { });
            
            state.form.loading = true

            const form_data = new FormData()

            form_data.append('file', state.form.file)
            form_data.append('month', moment(state.form.month).format('YYYY-MM'))

            apiClient.post(state.endpoint, form_data)
                .then(({ data }) => {
                    state.data = data
                })
                .catch(async error => {
                    errorMessage.value = null

                    if (error.response && error.response.status !== 500) {
                        const { status, statusText } = error.response
                        const message = JSON.parse(await error.response.data.text()).message
                        errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
                    }

                    if (error.response && error.response.status === 500) {
                        const { status, statusText } = error.response
                        const message = error.response.data.message
                        errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
                    }

                    message.error('Gagal mengungah!')
                })
                .finally(() => {
                    state.form.loading = false
                    
                    state.isForm = false
                    nextTick().then(() => {
                        state.isForm = true
                    })
                })
        }

        const handleSubmitOk = async () => {
            state.result.data = []
            errorMessage.value = null
            state.loading = true

            apiClient
                .post(state.endpoint, { simpan: state.selectedRows })
                .then(({ data }) => {
                    if (Array.isArray(data) && data.length === 0) {
                        message.warning('Tidak ada data!, pastikan sudah memilih datanya')
                        return
                    }

                    state.result.isShow = true
                    state.result.valid = 0
                    state.result.failed = 0
                    state.result.data = []

                    data.forEach((item, idx) => {
                        if (item.success) {
                            state.result.valid++
                        } else {
                            state.result.failed++
                            const branch = {}
                            state.result.data.push(branch)
                            branch.title = 'Baris ' + (idx + 1)
                            branch.key = idx
                            branch.children = []
                            if (item.model.errors) {
                                item.model.errors.forEach((v, i) => {
                                    branch.children.push({
                                        title: `Kolom ${v.field} : ${v.message}`,
                                        key: `${idx}-${i}`,
                                    })
                                })
                            }
                        }
                    })
                })
                .catch(async error => {
                    errorMessage.value = null
                    if (error.response) {
                        const { status, statusText } = error.response
                        const message = error.response.data.message
                        errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
                    }
                })
                .finally(() => {
                    state.loading = false
                    state.selectedRowKeys = []
                    state.selectedRows = []
                    state.data = []
                })
        }

        const btnDownloadTemplate = async () => {
            state.loadingTemplate = true

            apiClient
                .get('/sample-upload/mapping-user-kam.xlsx', {
                    params: {
                        _export: 'xls',
                    },
                    responseType: 'blob',
                    headers: {},
                })
                .then(response => {
                    if (response === undefined) {
                        errorMessage.value = `Kode error 500, No response from server`
                        return
                    }
                    const url = URL.createObjectURL(
                        new Blob([response.data], {
                            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        }),
                    )
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', `Mapping-KAM-Percustomer_${moment().format('DDMMYY_HHmm')}.xlsx`)
                    document.body.appendChild(link)
                    link.click()
                })
                .catch(async error => {
                    errorMessage.value = null
                    if (error.response) {
                        const { status, statusText } = error.response
                        const message = JSON.parse(await error.response.data.text()).message
                        errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
                    }
                })
                .finally(() => {
                    state.loadingTemplate = false
                })
        }

        const handleShowCancel = () => {
            state.result.isShow = false
            handleModalCancel()
        }

        return {
            errorMessage,
            state,
            formRef,
            btnUploadFile,
            handleSubmitOk,
            handleModalCancel,
            onFileChange,
            rowSelection,
            btnDownloadTemplate,
            handleShowCancel,
            disabledDate,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/css/form.scss';
</style>
